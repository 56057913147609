.App {
  text-align: center;
  background-image: url("/public/fotos/branding/fondo_bonviajes.jpeg");
}


*{
  box-sizing:border-box;
  margin: 0;
  
}


html, body{
  height:100%;
  margin:0;
  display: flex;
  flex-direction: column;
  background-image: url("/public/fotos/branding/fondo_bonviajes.jpeg");
  
  margin:0;
  padding:0;
}


.contenedor_menu{
 
  border-radius: 25px;
 

}



.iconos_info_contacto{

 
  max-width: 10px auto;
  
  
}

.logos_contacto{
  display: flex;
  margin-left: 25px;
}

.nombre_tour{
  border-radius: 25px;
  background-color: whitesmoke;
  text-align: center;
 /*  width: fit-content; */
 color: #CF1A6F;
}

.fondo{
  background-image: url("../public/fotos/branding/fondo_bonviajes.jpeg");
}

.contenedor_log_bv{
  width:100vw;
    height:100vh; 
}

.logo_bv{
  height: auto;
  width: 500px;
  margin: 0 auto;
  .one {
    grid-column: 1 / 2;
    grid-row: 1;
  }

}
.logo_bv_l{
  height: 150px;
  width: auto;
  text-align: center;
  padding: 20px;
  background-color: rgba(205, 63, 129,0.3);
  border-radius: 35px;
 
}



.botones_idioma{
  border: white;
  border-style: none;
  border-radius: 5px;
  text-align: right;
  margin-right: 30px;
}


.contenedor_centrar{
 
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
 /*  background-color: rgba(205, 63, 129,0.3); */
 background-image: url("../public/fotos/branding/fondo_bonviajes.jpeg") ;
}


.contendedor_all{
 /*  background-color: rgba(205, 63, 129,03) ; */
  background-image: url("../public/fotos/branding/fondo_bonviajes.jpeg") ;
}

.contenedor_login{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(205, 63, 129,03);
  background-image: url("../public/fotos/branding/fondo_bonviajes.jpeg") ; */
  height: 100%;
}


/*-------------------------menu navegacion entre paginas--------------------------------------------------*/
.menu{

  outline: none;
  text-decoration: none;
  height: fit-content;
   
  text-align: center;
 
  list-style: none;
 
 margin-left: 5px auto;
 list-style-type:none;
 
 
 
 width: fit-content;
 /* background-color:whitesmoke; */
 border-radius: 25px;

}

.menu li{
 
 position:relative;
 


}

.menu a:link,
.menu a:visited,
.menu a:focus {
 
  color: rgb(207, 26, 111);
  text-decoration: none;
}

.menu a:hover {
  background: rgb(207, 26, 111);
  color:whitesmoke;
  border-radius: 5px;
}

.menu a:active {
 
  color: white;
}


 .lista{
  color:rgb(205, 63, 129);
  display: flex;
  
  border-radius: 25px;
  border-color: #FF1493;
} 

/* nav > ul {
 
  flex-direction: row;
  align-items: center;
} */

.item_menu{
  display:grid;
  border-radius: 25px;
  color:rgb(205, 63, 129);
  list-style: none;
  font-size: 25px;
  font-weight: 500;
  grid-auto-columns:auto;
  text-decoration: none;
  padding:10px;
}

/*-----------------------------------------------------------------------------*/



/*------------------pagina detalles ----------------------------------*/
.marco_galeria_imagenes{
 /*  padding-top: 10px;
  padding-bottom: 10px; */
  background-color:white;
  border-radius: 25px;
  border: 1px solid rgb(207, 26, 111);
 height: fit-content;
  
}

.marco_galeria_imagenes_rosa{
  padding:8px;
  background-color: white;
 
  border: 1px solid #999999;
  border-radius: 15px;
}



.marco_galeria_imagenes_bco{
  padding-top: 3px;
  background-color:white;
 
}


.contenedor_detalles{
  
 /*  margin-top: 100px; */
 border-radius: 2px;
 border-color: #CF1A6F;

}

.contenedor_descripcion{
  justify-content: center;
}

.fondo_blanco_detalles{
  height: auto;
  min-width:250px;
  max-width: 1000px;
  font-size: large;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    background-color:white;
  border-radius: 25px;
  padding: 15px;
  border: 1px solid rgb(207, 26, 111);
 
}



.boton_whatsapp {
  /* border:  1px solid rgb(207, 26, 111); */
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  font-size: 16px;
  width: 300px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: blue;
  color: white;
}

/*------------------------------------------*/

.heading {
  color:#F9629F;
  text-align: center;
}


.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.info_contacto {
  grid-column: 1 / 3;
  grid-row: 1;
 
  color:#CF1A6F;
  
  
  margin-top: 5px;
  margin-left: 10px;
}

              
.boton_salir{
  grid-column: 1 / 3;
  grid-row: 3;
}

.fondo_blanco_precio{
 /*  margin-top: 40%; */
  height: auto;
  width: fit-content;
  font-size: large;
  justify-items: center;
  justify-content: center;

  grid-row: 2;
  grid-row: 2 / 2;
   /*  white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap; */
 /*  background-color:white; */
  border-radius: 25px;
  padding: 25px;
  border: 1px solid rgb(207, 26, 111);
}

.img_carousel{
  height: 500px;
  width: 320px;
  border: 2px solid #FF1493;
  border-radius: 25px;
  
}
.formato_fechas{
  
 
  word-spacing: 25px;
  padding-right: 25px;
  
}

.contenedor_margen_derecho{
  align-items: end;
}

.contenedor_links{
  background-color: rgb(205, 63, 129);
}

.barra_de_busqueda{
  width: 500px;
  height: 33px;
  font-size: 25px;
  flex-wrap: wrap;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.5px;
  border-color: darkgrey;
  
}

.selector_destino{

  width: 150px;
}

.selector{
  width: 200px;
  height: 28px;
  font-size: 15px;
  flex-wrap: wrap;
  border-style: solid;
  border-width: 0.5px;
  border-color: darkgrey;
}
 .barra_de_busqueda{
  height:50px ;
  width: 500px;
}
.contenedor_barra_busqueda{
  
  display: flex;
  width: fit-content;
  height: auto;
  align-items: center;
  margin: 0 auto; 
  
}



.barra_busca_destino{
  height: 40px;
  width: 500px;
  
 
} 
.input_fecha_tour{
  height: 50px;
  width: 350px;
}

.contenedor_busca_fecha{
  display: flex;
  flex-direction: row;
  justify-content: space-between, space-around;
}
.centrar{
  margin: 0 auto;
}



.formato_pagina{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  
  grid-auto-rows: minmax(100px, auto);
 /*  padding: 20px; */
  margin: 10px;
 /*  position: relative; */
  border-radius: 25px;
}

.formato_pagina_gray{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  padding: 20px;
  margin: 2%;
  background-color: rgb(234 179 8 / var(--tw-text-opacity));
}
.fondo_descripcion{
  border-radius: 12px;
  background-color: white;
  font-style: italic;
  padding: 15px;
}

.oculto{
  display: none;
}
.oculto_img{
  display:none;
  border: 1px solid rgb(207, 26, 111);
}

.boton_reservar{
  font-size: xx-large;
  border-radius: 5px;
  background-color: rgb(205, 63, 129);
  color:white;
 border-color: white;
 height: auto;
 border-radius: 25px;
}

.imagenes{
  width: 100%;
  height: 12rem;
  object-fit: cover;
  transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  transition-duration: 100ms;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.imagenes_detalles{
  width: 100%;
  height: 15rem;
  object-fit: cover;
}


.tarjetas_informativas{
  flex-wrap: wrap;
  background-color: #6b7d82;
  width:600px;
  height: auto;
  margin: 10px;
  padding: 10px;
  border-radius: 15px;
}

.boton_like{
  bottom: 1rem;
  right:1rem;
  padding: 0.5rem;
  z-index: 30;
  background-color: white;
  border-style: none;
}

.corazon_relleno{
  
  color: red;
  width: 1.25rem;
  height: 1.25rem;
}

.corazon_vacio{
  
  background-color: white;
  width: 1.25rem;
  height: 1.25rem;
}

.link_ver_detalles{
 position: relative;


}

/* .grid_columnas_detalles_tours{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 5px;
} */

.contenedor_general_inicio{
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-columns: minmax(50px, auto);


  width: 400px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);  

  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  height: 350px;
 
  /* border: 1px solid rgb(207, 26, 111); */
}
.contenedor_general{
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-columns: minmax(50px, auto);


  width: 400px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);  

  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
  
 
  /* border: 1px solid rgb(207, 26, 111); */
}

.contenedor_general_v{
  width: 400px;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  background-color: blueviolet;
}

.input_fecha{
  border-radius: 5px;
  position: relative;
  display: inline-block;
  height: 33px;
  width: 250px;
  border-style: solid;
  border-width: 0.5px;
  border-color: darkgrey;
  font-size: 18px;
}

.selector_rango_fechas{
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px; /*----cambiar este valor*/
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  
}

.input_fecha:active .selector_rango_fechas{
  display: block;
}

.contenedor_info{
  padding: 1rem;
 
}
.contenedor_formato{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.contenedor_estrella{
  display: flex;
  align-items: center;
}

.titulo_nombre_tour{
  font-size: 18px/* 20px */;
    line-height: 1.75rem/* 28px */;
    font-weight: 600;
    margin-bottom: 0.5rem/* 8px */;
}

.star_icon{
  height: 1.25rem;
  width: 1.25rem;
  --tw-text-opacity: 1;
    color: rgb(234 179 8 / var(--tw-text-opacity));
    margin-left: 0.25rem;
}

.ver_detalles_tour{
  font-size: 18px;
  color: white;
  border-top-right-radius: 25px;
  background-color: #a32669e4;
}


/* 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */


.contenedor_general{
  max-height: 500px;
}

/*---------------------------------------------MEDIA QUERIES------------------------------------------------------*/

/*--------- Para moviles y Tablets --------------------*/
@media (max-width:768px){

/*------------- Reglas para pagina principal-------------*/

  .formato_pagina{
    grid-template-columns: repeat(1, 1fr);
    margin-top: 20px;
  }

  .info_contacto{
    font-size: 14px;
  }

  .logo_bv{

    padding: 15px;
    height: auto;
    width: 250px;
  }
  .contenedor_logo{
    justify-content: center;
  }
  .contenedor_barra_busqueda{
    flex-direction: column;
   
    
  }

  .selector_rango_fechas{
    display: block;
    position: relative;
    flex-wrap: wrap;
    background-color: #f9f9f9;
    max-width: 100%; /*----cambiar este valor*/
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 4px 4px;
    z-index: 1;
    
    
  }
  
  .input_fecha:active .selector_rango_fechas{
    display: block;
   
  }

  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }

  .contenedor_general{
    max-width: 250px;
    font-size: 12px;
    max-height: 300px;
    
  }
  .contenedor_info{
    justify-items: flex-start;
  }

  .contenedor_formato{
    justify-items: flex-start;
  }

  .barra_busca_destino{
    max-width: 250px;
    margin: 15px;
  }

 
  .contenedor_categoria_tours{
    max-width: 250px;
    background-color: #f8f8f8;
    border-radius: 25px;
  }

  .contenedor_selecciones{
    min-width:250px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px;
    flex-wrap: wrap;
    
  }

  .menu{
   background-color: whitesmoke;
   padding: 5px;
  } 

  .lista{
    margin-left: 0px ;
    padding: 0px;
  }
  
  .item_menu{
    display:grid;
    border-radius: 25px;
    color:rgb(205, 63, 129);
    list-style: none;
    font-size: 16px;
    font-weight: 500;
    grid-auto-columns:auto;
    text-decoration: none;
    padding:10px;
  }

  .h1{
    font-size: 20px;
  }

  /*----------------------Reglas paara Pagina Detalles-----------------------*/

  .link_ver_detalles{
    font-size: 14px;
  }
  

}

/*---------Reglas para Desktop Igual o mayores a 768 px----------*/

@media (min-width:768px){

  /*----           Reglas para pagina principal                     ---  */

  .info_contacto{
    font-size: 18px;
  }
  .formato_pagina{
    grid-template-columns: repeat(3, 1fr);
    margin-top: 20px;
  }



 
  .contenedor_logo{
    justify-content: center;
    width:100vw;
    height:100vh; 
  }
  .contenedor_barra_busqueda{
    flex-direction: row;
    margin: 5px;
    justify-content: space-between ,space-around;
  }

  .contenedor_selecciones{
   
    justify-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between ,space-around;
    margin: 5px;
       
  }

  .contenedor_selecciones label{
    font-size: 25px;
  }

  .radiogroup_selecciones{
    justify-items: center;
    display: flex;
    margin: 15px;
  }

  .input_radio_selecciones{
    margin: 7px;
  }

  /*----------------------Reglas paara Pagina Detalles-----------------------*/

}